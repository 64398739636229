import React from 'react'

import { css } from 'styled-components'
import { color } from '../styles/theme'
import GlobalWrapper from '@gtg/components/globalWrapper'

const customGlobalStyle = css`
  body {
    font-family: lato;
    letter-spacing: 0.06px;
    font-weight: 400;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 900;
    letter-spacing: -0.3px;
    line-height: 1.2;
  }

  em,
  b {
    font-weight: 900;
  }
`

const CustomGlobalWrapper = props => {
  return (
    <GlobalWrapper
      customGlobalStyle={customGlobalStyle}
      roundness={1}
      selectionColor={color.brand400}
      themeColor={color.brand500}
      hoverColor={color.brand400}
    >
      {props.children}
    </GlobalWrapper>
  )
}

export default CustomGlobalWrapper
