import { color, fontSize } from '@gtg/styles/theme'

color.brand25 = '#F1FAFF'
color.brand50 = '#E3F6FF'
color.brand100 = '#B7E4FA'
color.brand200 = '#8CD3F6'
color.brand300 = '#4CB3E7'
color.brand400 = '#2597D0'
color.brand500 = '#087FB9'
color.brand600 = '#006494'
color.brand700 = '#094C6D'
color.brand800 = '#113B50'

export { color, fontSize }
